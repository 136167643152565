import { useMediaQuery, useTheme } from "@material-ui/core";
import { Org, Team } from "@ollie-sports/models";
import { View, Image, TouchableOpacity } from "react-native-web";
import { ShadowView } from "../../components/ShadowView";
import { addHeightAndWidthToURI, getImageDimensionsFromURI } from "../../utils/imageUrlHelpers";
import { StyledText } from "../../components/StyledText";
import { prettyGender } from "../../utils/genderUtils";
import { getCurrentLocale, translate } from "@ollie-sports/i18n";
import { COLORS, Team__StaffPresets, api, compute } from "@ollie-sports/core";
import { getBifrost } from "../../services/bifrost.service";
import { EditButton } from "../../components/EditButton";
import { openTeamEdit } from "./TeamEdit";
import { PlusCircleIcon, TrashIcon } from "@heroicons/react/24/outline";
import { useRef, useState } from "react";
import { downscaleImage, fileToBase64 } from "../../utils/fileUtils";
import { openErrorToast, openSuccessToast } from "../../utils/openErrorToast";
import { CenteredLoader } from "../../components/CenteredLoader";
import getConfirm from "../../components/modals/getConfirm";
import { getCurrentUserAccountId, useCurrentUserTeamsAndOrgs } from "../../hooks/commonDataUtils";
import { CameraIcon } from "@heroicons/react/20/solid";
import { SingleImageDropZone } from "../../components/DropZone";
import clsx from "clsx";

export function TeamHome(p: { team: Team; org?: Org }) {
  const theme = useTheme();
  const { data: teamsAndOrgs } = useCurrentUserTeamsAndOrgs();

  const isStaffOrOrgAdmin = compute.isStaffOrOrgAdmin({
    accountId: getCurrentUserAccountId(),
    team: p.team,
    org: teamsAndOrgs?.orgs.find(o => o.id === p.team.orgId)
  });
  const mobileDevice = useMediaQuery(theme.breakpoints.down("sm"));
  const [isUploadingImage, setIsUploadingImage] = useState(false);
  const highestRankingStaff = compute.team.extractHighestRankingStaff(p.team);
  const { data: highestRankingStaffAccount } = getBifrost().account__client__getAccount.useClient(
    {
      accountId: highestRankingStaff?.accountId ?? ""
    },
    { enabled: !!highestRankingStaff }
  );
  const INPUT_ID = useRef(Math.random() + "").current;

  const handleImageFile = async (file: File) => {
    setIsUploadingImage(true);
    const base64 = await fileToBase64(file);
    const { blob, height, width } = await downscaleImage(base64, 1000);
    if (blob && file) {
      try {
        const snapshot = await api.team__client__uploadTeamPhoto({
          teamId: p.team.id,
          blob
        });

        if (snapshot) {
          const firebaseUri = await snapshot.ref.getDownloadURL();
          const firebaseUriWithDimensions = addHeightAndWidthToURI({
            uri: firebaseUri,
            height,
            width
          });

          await getBifrost().team__client__setTeamPhoto.fetchClient({
            teamId: p.team.id,
            teamPhotoUri: firebaseUriWithDimensions
          });
        }
      } catch (e) {
        openErrorToast(
          translate({
            defaultMessage: "There was a problem uploading the image. Please try again or contact support@olliesports.com"
          })
        );
      }
    }
    setIsUploadingImage(false);
  };

  return (
    <View style={{ flex: 1 }}>
      <View style={{ flex: 1, flexDirection: "column" }}>
        <View style={{ flex: 1, marginRight: mobileDevice ? 0 : 10 }}>
          <div style={{ borderRadius: 8, flexDirection: "row" }}>
            <View style={{ flex: 1, flexDirection: "row" }}>
              <View style={{ flex: 1 }}>
                <StyledText style={{ marginTop: 4 }}>{p.team.shortName}</StyledText>
                <StyledText style={{ marginTop: 4 }}>{`${p.team.birthYear} ${prettyGender(p.team.gender)}`}</StyledText>
                {highestRankingStaff && highestRankingStaffAccount ? (
                  <StyledText style={{ marginTop: 4 }}>{`${
                    Team__StaffPresets(getCurrentLocale())[highestRankingStaff.role].staffTitle
                  }: ${highestRankingStaffAccount.firstName} ${highestRankingStaffAccount.lastName}`}</StyledText>
                ) : null}
              </View>
              {isStaffOrOrgAdmin ? (
                <EditButton
                  size={36}
                  onPress={async () => {
                    openTeamEdit({
                      initialTeam: p.team
                    });
                  }}
                />
              ) : null}
            </View>
          </div>
        </View>
        <div>
          {isUploadingImage ? (
            <CenteredLoader style={{ height: 200, width: 300 }} />
          ) : (
            <div className={clsx([isStaffOrOrgAdmin ? "" : "pointer-events-none"])}>
              <SingleImageDropZone
                onDrop={handleImageFile}
                style={{ minHeight: 200 }}
                className="rounded-xl flex-1 bg-gray-300 max-w-xs mt-3"
                dragClassName="bg-green-500 opacity-70"
              >
                {p.team.teamPhotoUri ? (
                  <div className="flex flex-col relative">
                    {isStaffOrOrgAdmin ? (
                      <div
                        className="shadow-lg bg-white"
                        style={{ padding: 5, position: "absolute", top: 10, right: 10, borderRadius: 4 }}
                      >
                        <EditButton
                          onPress={async () => {
                            document.getElementById(INPUT_ID)!.click();
                          }}
                        />
                      </div>
                    ) : null}
                    {isStaffOrOrgAdmin ? (
                      <div
                        className="shadow-lg bg-white"
                        style={{ padding: 5, position: "absolute", top: 50, right: 10, borderRadius: 4 }}
                      >
                        <TouchableOpacity
                          style={{ height: 20, width: 20 }}
                          onPress={async () => {
                            const confirm = await getConfirm({
                              title: translate({ defaultMessage: "Delete Team Photo?" }),
                              subtitle: translate({ defaultMessage: "Are you sure you want to delete this team photo?" }),
                              confirmButtonColor: "red",
                              confirmText: translate.common.Delete
                            });
                            if (confirm) {
                              try {
                                await getBifrost().team__client__deleteTeamPhoto.fetchClient({
                                  teamId: p.team.id
                                });
                              } catch (e) {
                                openErrorToast(
                                  translate({
                                    defaultMessage:
                                      "There was a problem deleting the image. Please try again or contact support@olliesports.com"
                                  })
                                );
                              }
                            }
                          }}
                        >
                          <TrashIcon color={COLORS.red} />
                        </TouchableOpacity>
                      </div>
                    ) : null}
                    <img className="max-w-full h-auto shadow-md rounded-xl" src={p.team.teamPhotoUri} alt="description" />
                  </div>
                ) : (
                  <div
                    className="hover:opacity-70 cursor-pointer flex flex-col items-center justify-center h-56"
                    onClick={() => {
                      document.getElementById(INPUT_ID)!.click();
                    }}
                    style={{ height: 200, width: 300, alignItems: "center", justifyContent: "center" }}
                  >
                    {isStaffOrOrgAdmin ? (
                      <div className="flex flex-col items-center justify-center">
                        <View style={{ width: 50, height: 50, marginTop: 15 }}>
                          <CameraIcon color={"white"} />
                        </View>
                        <span className="text-white">{translate({ defaultMessage: "Drag image or click" })}</span>
                      </div>
                    ) : (
                      <div className="text-white">{translate({ defaultMessage: "No team image" })}</div>
                    )}
                  </div>
                )}
              </SingleImageDropZone>
            </div>
          )}
        </div>
      </View>
      <SelectTeamPhotoInput id={INPUT_ID} handleImageFile={handleImageFile} />
    </View>
  );
}

function SelectTeamPhotoInput(p: { handleImageFile: (file: File) => void; id: string }) {
  return (
    <input
      id={p.id}
      accept={"image/*"}
      multiple={false}
      onChange={async event => {
        var file = event.target.files?.[0];
        if (file) {
          p.handleImageFile(file);
        }
      }}
      type="file"
      style={{ display: "none" }}
    />
  );
}
