import { AccountId, Team__Shell, TeamId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import { joinTeamToOrgOrRemoveTeamFromOrg } from "../../internal-utils/org-utils";

export async function team__server__updateShell(p: { updatedTeamShell: Team__Shell; teamId: TeamId; accountId: AccountId }) {
  // SERVER_ONLY_TOGGLE

  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const team = await h.Team.getDoc(p.teamId);
  if (!team) {
    throw new Error("Unable to find team");
  }

  const { logoUri, teamPhotoUri, squads, ...rest } = p.updatedTeamShell;

  await h.Team.updateShallow({
    id: p.teamId,
    doc: {
      ...rest,
      squads: squads ?? h._MagicDeleteValue,
      logoUri: logoUri ?? h._MagicDeleteValue,
      teamPhotoUri: teamPhotoUri ?? h._MagicDeleteValue
    }
  });

  if (p.updatedTeamShell.orgId !== team.orgId) {
    p.updatedTeamShell.orgId
      ? await joinTeamToOrgOrRemoveTeamFromOrg({
          type: "join",
          actionPerformedByAccountId: p.accountId,
          orgId: p.updatedTeamShell.orgId,
          teamId: p.teamId
        })
      : await joinTeamToOrgOrRemoveTeamFromOrg({
          type: "remove",
          actionPerformedByAccountId: p.accountId,
          teamId: p.teamId
        });
  }
  // SERVER_ONLY_TOGGLE
}

team__server__updateShell.auth = async (r: any) => await validateToken(r);

// i18n certified - complete
