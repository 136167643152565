import { AccountId, PlayerId, TeamId } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { BatchTask } from "@ollie-sports/firebase";
import { getUniversalHelpers } from "../../helpers";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";
import { ObjectKeys } from "../../utils/object-keys";
import { getDeleteFromTeamBatchTasks } from "../player/player__deleteFromTeam";

interface Props {
  teamId: TeamId;
  staffAccountId: AccountId;
  playerIdsToDelete: PlayerId[];
}

export async function team__server__deleteStaffMemberAndAssociatedPlayers(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let tasks: BatchTask[] = [];

  tasks.push(
    await h.Team.update(
      {
        id: p.teamId,
        doc: {
          accounts: {
            [p.staffAccountId]: {
              roles: { staff: h._MagicDeleteValue },
              staffTitle: h._MagicDeleteValue,
              additionalPermissions: {
                squad_a_staff: h._MagicDeleteValue,
                squad_b_staff: h._MagicDeleteValue,
                squad_c_staff: h._MagicDeleteValue
              }
            }
          }
        }
      },
      { returnBatchTask: true }
    )
  );

  for (let i = 0; i < p.playerIdsToDelete.length; i++) {
    const deletePlayerTasks = await getDeleteFromTeamBatchTasks({
      data: { playerId: p.playerIdsToDelete[i], ignoreUpdateDerivedForTeam: true }
    });
    tasks = [...tasks, ...deletePlayerTasks];
  }

  await h._BatchRunner.executeBatch(tasks);

  // Must wait until player data is actually deleted before running the code to update the team derived code
  await updateDerivedForTeam({ teamId: p.teamId, executeImmediate: true });
  // SERVER_ONLY_TOGGLE
}
team__server__deleteStaffMemberAndAssociatedPlayers.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};

// i18n certified - complete
