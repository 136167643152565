import { AccountId, TeamId, Team__Account, Team__StaffTypes } from "@ollie-sports/models";
import express from "express";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";

export async function team__server__addStaff(p: { accountId: AccountId; teamId: TeamId; staffRole: Team__StaffTypes }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const team = await h.Team.getDoc(p.teamId);

  if (!team) {
    throw new Error("Unable to add staff to a team that doesn't exist");
  }

  if (team.accounts[p.accountId]) {
    throw new Error("Unable to add staff to a team they already belong to");
  }

  const teamAccount: Team__Account = {
    exists: true,
    roles: {
      staff: true
    },
    staffTitle: p.staffRole,
    additionalPermissions: {
      manageEvents: true,
      manageRolesAndPermissions: true,
      manageRoster: true,
      recordStats: true,
      viewIndividualStats: true
    }
  };

  if (team?.squads && teamAccount.additionalPermissions) {
    teamAccount.additionalPermissions.squad_a_staff = true;
    teamAccount.additionalPermissions.squad_b_staff = true;
    if (team?.squads.c) {
      teamAccount.additionalPermissions.squad_c_staff = true;
    }
  }

  await h.Team.update({
    id: p.teamId,
    doc: {
      accounts: { [p.accountId]: teamAccount }
    }
  });

  await updateDerivedForTeam({ teamId: p.teamId, executeImmediate: true });
  // SERVER_ONLY_TOGGLE
}

team__server__addStaff.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};

// i18n certified - complete
