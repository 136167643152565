import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { TeamId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { ObjectKeys } from "../../utils";

export async function team__server__checkForOnboardingComplete(p: { teamId: TeamId }): Promise<boolean> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const team = await h.Team.getDoc(p.teamId);

  let numCompletedSteps = 0;

  // If we can't find the team or the team has already been onboarded, nothing left to do
  if (!team) {
    return false;
  }

  if (team.hasOnboarded) {
    return true;
  }

  // Check for at least 5 profiles
  if (ObjectKeys(team.accounts).length >= 5) {
    numCompletedSteps += 1;
  }

  const completedGame =
    (
      await h.CalendarEntry.query({
        where: [
          { teamId: ["==", p.teamId] },
          { derived: { endGameDetails: { gameResult: ["in", ["win", "loss", "tie"]] } } } as any
        ],
        limit: 1
      })
    ).docs.length > 0;

  if (completedGame) {
    numCompletedSteps += 1;
  }

  // If the 2 steps are complete, set the onboarding status to finished
  if (numCompletedSteps === 2) {
    await h.Team.update({
      id: p.teamId,
      doc: {
        hasOnboarded: true
      }
    });
    return true;
  }
  return false;
  // SERVER_ONLY_TOGGLE
}
team__server__checkForOnboardingComplete.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
