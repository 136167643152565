import { TeamId, AccountId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function team__client__hideTeamSetupChecklist(p: { teamId: TeamId }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  await h.Team.update({
    id: p.teamId,
    doc: { hideTeamSetupChecklist: true }
  });
}

// i18n certified - complete
