import { ObjectKeys, Team__StaffPresets, COLORS } from "@ollie-sports/core";
import { translate, getCurrentLocale } from "@ollie-sports/i18n";
import { Team__Account, Team__StaffTypes } from "@ollie-sports/models";
import { View } from "react-native-web";
import { CoolCheckboxInput } from "../../components/Inputs/CoolCheckboxInput";
import { RadioInputPillbox } from "../../components/RadioInputPillbox";
import { StyledText } from "../../components/StyledText";

export function TeamAccountEdit(p: {
  teamAccount: Team__Account;
  onUpdateTeamAccount: (updatedTeamAccount: Team__Account) => void;
}) {
  return (
    <View>
      <View>
        <CoolCheckboxInput
          label={translate.common.Staff}
          value={!!p.teamAccount.roles.staff}
          labelType="inside"
          onChange={newVal => {
            if (newVal === true) {
              const newTeamAccount = { ...p.teamAccount };
              newTeamAccount.roles.staff = true;
              newTeamAccount.staffTitle = Team__StaffTypes.staffMember;
              p.onUpdateTeamAccount(newTeamAccount);
            } else {
              const newTeamAccount = { ...p.teamAccount };
              delete newTeamAccount.roles.staff;
              delete newTeamAccount.staffTitle;
              newTeamAccount.staffTitle = Team__StaffTypes.staffMember;
              p.onUpdateTeamAccount(newTeamAccount);
            }
          }}
        />
        {!!p.teamAccount.roles.staff ? (
          <RadioInputPillbox
            style={{ marginTop: 15 }}
            onChange={newVal => {
              const newTeamAccount = { ...p.teamAccount };
              newTeamAccount.staffTitle = newVal as Team__StaffTypes;
              p.onUpdateTeamAccount(newTeamAccount);
            }}
            options={ObjectKeys(Team__StaffTypes).map(staffType => {
              return {
                label: Team__StaffPresets(getCurrentLocale())[staffType].staffTitle,
                value: staffType
              };
            })}
            pillHeight={30}
            unselectedBackgroundColor={COLORS.grey_07}
            unselectedBorderColor={COLORS.grey}
            unselectedTextColor={COLORS.black}
            value={p.teamAccount.staffTitle ?? ""}
          />
        ) : null}
      </View>
      <View style={{ marginTop: 30 }}>
        <StyledText style={{ fontWeight: "bold" }}>{translate.common.Permissions}</StyledText>
        <CoolCheckboxInput
          label={translate({ defaultMessage: "Manage Calendar" })}
          labelType="inside"
          onChange={newVal => {
            const newTeamAccount = { ...p.teamAccount };
            if (newVal) {
              if (newTeamAccount.additionalPermissions) {
                newTeamAccount.additionalPermissions.manageEvents = true;
              } else {
                newTeamAccount.additionalPermissions = { manageEvents: true };
              }
            } else {
              delete newTeamAccount.additionalPermissions?.manageEvents;
            }
            p.onUpdateTeamAccount(newTeamAccount);
          }}
          value={p.teamAccount.additionalPermissions?.manageEvents ?? false}
        />
        <CoolCheckboxInput
          label={translate({ defaultMessage: "Edit Roster" })}
          labelType="inside"
          onChange={newVal => {
            const newTeamAccount = { ...p.teamAccount };
            if (newVal) {
              if (newTeamAccount.additionalPermissions) {
                newTeamAccount.additionalPermissions.manageRoster = true;
              } else {
                newTeamAccount.additionalPermissions = { manageRoster: true };
              }
            } else {
              delete newTeamAccount.additionalPermissions?.manageRoster;
            }
            p.onUpdateTeamAccount(newTeamAccount);
          }}
          value={p.teamAccount.additionalPermissions?.manageRoster ?? false}
        />
        <CoolCheckboxInput
          label={translate({ defaultMessage: "Record Stats" })}
          labelType="inside"
          onChange={newVal => {
            const newTeamAccount = { ...p.teamAccount };
            if (newVal) {
              if (newTeamAccount.additionalPermissions) {
                newTeamAccount.additionalPermissions.recordStats = true;
              } else {
                newTeamAccount.additionalPermissions = { recordStats: true };
              }
            } else {
              delete newTeamAccount.additionalPermissions?.recordStats;
            }
            p.onUpdateTeamAccount(newTeamAccount);
          }}
          value={p.teamAccount.additionalPermissions?.recordStats ?? false}
        />
        <CoolCheckboxInput
          label={translate({ defaultMessage: "View Individual Stats" })}
          labelType="inside"
          onChange={newVal => {
            const newTeamAccount = { ...p.teamAccount };
            if (newVal) {
              if (newTeamAccount.additionalPermissions) {
                newTeamAccount.additionalPermissions.viewIndividualStats = true;
              } else {
                newTeamAccount.additionalPermissions = { viewIndividualStats: true };
              }
            } else {
              delete newTeamAccount.additionalPermissions?.viewIndividualStats;
            }
            p.onUpdateTeamAccount(newTeamAccount);
          }}
          value={p.teamAccount.additionalPermissions?.viewIndividualStats ?? false}
        />
        <CoolCheckboxInput
          label={translate({ defaultMessage: "Manage Roles & Permissions" })}
          labelType="inside"
          onChange={newVal => {
            const newTeamAccount = { ...p.teamAccount };
            if (newVal) {
              if (newTeamAccount.additionalPermissions) {
                newTeamAccount.additionalPermissions.manageRolesAndPermissions = true;
              } else {
                newTeamAccount.additionalPermissions = { manageRolesAndPermissions: true };
              }
            } else {
              delete newTeamAccount.additionalPermissions?.manageRolesAndPermissions;
            }
            p.onUpdateTeamAccount(newTeamAccount);
          }}
          value={p.teamAccount.additionalPermissions?.manageRolesAndPermissions ?? false}
        />
      </View>
    </View>
  );
}
