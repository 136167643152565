import { Team } from "@ollie-sports/models";
import { openModal } from "../../components/modals/imperativeModal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { View } from "react-native-web";
import { translate } from "@ollie-sports/i18n";
import { useState } from "react";
import { Form, PrettyCoolTextInput, PrettyCoolTextInputWithLabel } from "../../components/Form";
import { openErrorToast } from "../../utils/openErrorToast";
import { dequal } from "dequal";
import { useImmutableState } from "../../utils/useImmutableState";
import { getBifrost } from "../../services/bifrost.service";

type Props = {
  initialTeam: Team;
};

export function openTeamEdit(p: Props) {
  return new Promise<void>(res => {
    const modal = openModal({
      body: (
        <TeamEdit
          {...p}
          onRequestDismiss={() => {
            modal.close();
            res();
          }}
        />
      )
    });
  });
}

function TeamEdit(p: Props & { onRequestDismiss: () => void }) {
  const [team, setTeam] = useImmutableState(p.initialTeam);
  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            title={translate({ defaultMessage: "Edit Team" })}
            bottomButton={{
              title: translate.common.Save,
              enabled: isFormValid,
              onPress: async () => {
                try {
                  await getBifrost().team__server__update.fetchServer({
                    teamPartial: {
                      id: team.id,
                      name: team.name,
                      shortName: team.shortName
                    }
                  });
                  p.onRequestDismiss();
                } catch (e) {
                  openErrorToast(
                    translate({
                      defaultMessage:
                        "There was a problem saving the registration package. Please try again or contact support@olliesports.com"
                    })
                  );
                }
              }
            }}
            onRequestDismiss={() => {
              if (
                !dequal(team, p.initialTeam) &&
                !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
              ) {
                return;
              }

              p.onRequestDismiss();
            }}
          >
            <PrettyCoolTextInputWithLabel
              isRequired
              onChange={newVal => {
                setTeam({
                  name: newVal ?? ""
                });
              }}
              label={translate.common.Name}
              value={team.name}
            />
            <PrettyCoolTextInputWithLabel
              style={{ marginTop: 15 }}
              isRequired
              onChange={newVal => {
                setTeam({
                  shortName: newVal ?? ""
                });
              }}
              label={translate.common.ShortName}
              value={team.shortName}
            />
          </FullScreenModal>
        );
      }}
    />
  );
}
