import { Team } from "@ollie-sports/models";
import { BifrostSubscription, createBifrostSubscription } from "@ollie-sports/react-bifrost";
import { getUniversalHelpers } from "../../helpers";

export async function teams__server__getTeamNoAuth(p: { teamId: string }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const team = await h.Team.getDoc(p.teamId);
  return team;
  // SERVER_ONLY_TOGGLE
}

teams__server__getTeamNoAuth.auth = async (req: any) => {};

// i18n certified - complete
