import { Account, Team, Team__Account, Team__StaffTypes } from "@ollie-sports/models";
import { openModal } from "../../components/modals/imperativeModal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { translate } from "@ollie-sports/i18n";
import { openErrorToast } from "../../utils/openErrorToast";
import { dequal } from "dequal";
import { useImmutableState } from "../../utils/useImmutableState";
import { ObjectKeys, compute } from "@ollie-sports/core";
import { getBifrost } from "../../services/bifrost.service";
import getConfirm from "../../components/modals/getConfirm";
import getAlert from "../../components/modals/getAlert";
import { TeamAccountEdit } from "./TeamAccountEdit";

type Props = { account: Account; team: Team };

export function openGuardianStaffEditModal(p: Props) {
  const initalTeamAccount = p.team.accounts?.[p.account.id];
  if (!initalTeamAccount) {
    return;
  }
  return new Promise<void>(res => {
    const modal = openModal({
      body: (
        <GuardianStaffEditModal
          {...p}
          initialTeamAccount={initalTeamAccount}
          onRequestDismiss={() => {
            modal.close();
            res();
          }}
        />
      )
    });
  });
}

function GuardianStaffEditModal(p: Props & { initialTeamAccount: Team__Account; onRequestDismiss: () => void }) {
  const [teamAccount, setTeamAccount] = useImmutableState(p.initialTeamAccount);

  return (
    <FullScreenModal
      onRequestDismiss={async () => {
        if (
          !dequal(teamAccount, p.initialTeamAccount) &&
          !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" }))
        ) {
          return;
        }
        p.onRequestDismiss();
      }}
      title={`${p.account.firstName} ${p.account.lastName}`}
      bottomButton={{
        title: translate.common.Save,
        enabled: true,
        onPress: async () => {
          try {
            const headCoachLimit = compute.team.teamExtractSquadKeys(p.team.squads).length || 1;

            if (
              teamAccount.staffTitle === Team__StaffTypes.headCoach &&
              p.initialTeamAccount.staffTitle !== Team__StaffTypes.headCoach &&
              (
                ObjectKeys(p.team.accounts).filter(
                  id => p.team.accounts[id]?.staffTitle === Team__StaffTypes.headCoach && id !== p.account.id
                ) ?? []
              ).length >= headCoachLimit
            ) {
              getAlert({
                subtitle: `${translate(
                  {
                    defaultMessage: `Your team can only have {headCoachLimit} head {headCoachLimit, plural, zero {coaches} one {coach} other {coaches}}.`
                  },
                  { headCoachLimit }
                )} ${translate(
                  {
                    defaultMessage: `If you need more than {headCoachLimit} head {headCoachLimit, plural, zero {coaches} one {coach} other {coaches}}, please contact us at {supportEmail}`
                  },
                  { supportEmail: "support@olliesports.com", headCoachLimit }
                )}`,
                title: translate({
                  defaultMessage: "Too Many Head Coaches",
                  description: "Alert title warning user about too many head coaches"
                })
              });
              return;
            }

            if (teamAccount.roles.staff && !compute.team.hasAnyTeamPermission({ teamAccount: teamAccount })) {
              const confirm = await getConfirm({
                title: translate({ defaultMessage: "Staff with no permissions" }),
                subtitle: translate({
                  defaultMessage:
                    "You are trying to save a staff member but they don't have any permissions. Are you sure you want to continue?"
                }),
                cancelText: translate.common.Cancel,
                confirmText: translate.common.Continue,
                confirmButtonColor: "blue"
              });
              if (!confirm) {
                return;
              }
            }
            await getBifrost().team__server__setTeamAccount.fetchServer({
              accountId: p.account.id,
              teamAccount,
              teamId: p.team.id,
              forceRecalculateDerived: true
            });
            p.onRequestDismiss();
          } catch (e) {
            openErrorToast(
              translate({
                defaultMessage: "There was a problem with the update. Please try again or contact support@olliesports.com"
              })
            );
          }
        }
      }}
    >
      <TeamAccountEdit
        onUpdateTeamAccount={newTeamAccount => {
          setTeamAccount(newTeamAccount);
        }}
        teamAccount={teamAccount}
      />
    </FullScreenModal>
  );
}
