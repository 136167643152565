import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { View } from "react-native-web";
import { CenteredLoader } from "../../components/CenteredLoader";
import { translate } from "@ollie-sports/i18n";
import { Team, TeamSettings } from "@ollie-sports/models";
import { useCurrentUserTeamsAndOrgs } from "../../hooks/commonDataUtils";
import { TeamsTable } from "../../components/TeamsTable";
import { getBifrost } from "../../services/bifrost.service";

export default function MyTeams() {
  const { data: teamsAndOrgs } = useCurrentUserTeamsAndOrgs();

  const teams = teamsAndOrgs?.teams;
  const { data: teamSettings } = getBifrost().teamSettings__client__getMultiTeamSettingsSubscription.useClientSubscription(
    {
      teamIds: teams?.map(a => a.id) || []
    },
    { enabled: !!teams }
  );

  const teamSettingsMap = teamSettings?.reduce((acc, ts) => {
    acc[ts.id] = ts;
    return acc;
  }, {} as Record<string, TeamSettings>);

  const teamsWithSettingsByTeamId =
    teams && teamSettings
      ? teams.reduce((acc, team) => {
          acc[team.id] = { settings: teamSettingsMap?.[team.id], team };
          return acc;
        }, {} as Record<string, { settings?: TeamSettings; team: Team }>)
      : {};

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {teams && teamSettings ? <MyTeamsInner teamsWithSettingsByTeamId={teamsWithSettingsByTeamId} /> : <CenteredLoader />}
      </View>
    </Box>
  );
}

function MyTeamsInner(p: { teamsWithSettingsByTeamId: Record<string, { settings?: TeamSettings; team: Team }> }) {
  return (
    <View style={{ flex: 1 }}>
      <View style={{ flexDirection: "row" }}>
        <h1 className="text-2xl sm:text-4xl mt-4">{translate.common.MyTeams}</h1>
      </View>
      <TeamsTable teamsWithSettingsByTeamId={p.teamsWithSettingsByTeamId} />
    </View>
  );
}
