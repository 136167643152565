import { TeamId, CODE_TYPES, CodeTeam } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { findUnusedCode } from "../common.api";
import { getExpiryDate } from "../teams.api";

export async function teams__server__getInviteCodeNoAuth(p: { teamId: TeamId; type: CODE_TYPES.joinTeam }): Promise<CodeTeam> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let code = await findUnusedCode();

  const codeObj: CodeTeam = {
    id: h.Code.generateId(),
    createdAtMS: Date.now(),
    teamId: p.teamId,
    codeType: p.type,
    expiryDateMS: getExpiryDate(),
    code: code
  };
  await h.Code.add({ doc: codeObj });

  return codeObj;
}

teams__server__getInviteCodeNoAuth.auth = async (req: any) => {};
