import {
  Team,
  Team__Shell,
  Team__StaffTypes,
  TEAM_TYPES,
  Team__Account,
  TEAM_ROLES,
  PrettyPlayer,
  PrettyPlayerBundle,
  Team__NonStaffTypes,
  PlayerBundle__AccountType,
  AssetData,
  TEAM_SPORTS_WITH_STATS,
  MVPVotingMode,
  OrgTeamTag,
  TEAM_SPORT
} from "@ollie-sports/models";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers, getServerHelpers } from "../../helpers";
import { joinTeamToOrgOrRemoveTeamFromOrg } from "../../internal-utils/org-utils";
import { team__server__addPlayer } from "./team__addPlayer.api";
import { player__server__addBundleLinkToPlayer } from "../player";
import { playerBundle__server__addPlayerBundle } from "../playerBundle";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";
import { getDefaultEnabledFeaturesForTeam } from "../../utils/team.utils";
import { isProduction } from "../../utils/env-helpers";
import axios from "axios";
import { Team__StaffPresets } from "../../constants";
import { ObjectKeys, notifyOfExtremelyImportantError } from "../../utils";
import _ from "lodash";
import { SimpleQuery } from "@ollie-sports/firebase-lift";

export async function team__server__createNewTeam(p: {
  teamShell: Team__Shell;
  initialAccountId: string;
  initialStaffType?: Team__StaffTypes;
  selectedMemberRole?: Team__NonStaffTypes;
  playerToAdd?: PrettyPlayer;
  playerBundle?: PrettyPlayerBundle;
  assignedOrgTags?: Record<string, true>;
}): Promise<Team> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const roles: {
    staff?: boolean | undefined;
    athlete?: boolean | undefined;
    guardian?: boolean | undefined;
  } =
    p.selectedMemberRole === Team__NonStaffTypes.athlete
      ? { athlete: true }
      : p.selectedMemberRole === Team__NonStaffTypes.guardian
      ? { guardian: true }
      : {};
  if (p.initialStaffType) {
    roles.staff = true;
  }

  const pendingTeam: Team = {
    id: h.Team.generateId(),
    accounts: {},
    country: p.teamShell.country,
    createdAtMS: Date.now(),
    deletedAtMS: 0,
    orgId: p.teamShell.orgId,
    derived: { activePlayerIds: {}, activePlayerBundleIds: {} },
    gender: p.teamShell.gender,
    name: p.teamShell.name,
    shortName: p.teamShell.shortName,
    teamSport: p.teamShell.teamSport,
    teamType: p.teamShell.teamType,
    timezone: p.teamShell.timezone,
    birthYear: p.teamShell.birthYear,
    schoolId: p.teamShell.schoolId,
    disableCovid19Confirm: true,
    enabledFeatures: getDefaultEnabledFeaturesForTeam({
      teamSport: p.teamShell.teamSport,
      teamType: p.teamShell.teamType
    }),
    province: p.teamShell.province,
    regionCode: p.teamShell.regionCode,
    logoUri: p.teamShell.logoUri,
    assignedOrgTagIds: p.assignedOrgTags,
    votingMode: MVPVotingMode.standard
  };

  const initialTeamAccount: Team__Account = {
    exists: true,
    staffTitle: p.initialStaffType,
    roles: roles,
    additionalPermissions: {
      manageRoster: true,
      manageEvents: true,
      recordStats: true,
      manageRolesAndPermissions: true,
      viewIndividualStats: true
    }
  };

  if (p.teamShell.squads) {
    initialTeamAccount.additionalPermissions = {
      ...initialTeamAccount.additionalPermissions,
      ...{ squad_a_staff: true, squad_b_staff: true, squad_c_staff: true }
    };

    pendingTeam.squads = p.teamShell.squads;
    pendingTeam.enabledFeatures = { ...(pendingTeam.enabledFeatures || {}), squads: true };
  }

  if (!p.teamShell.squads || !ObjectKeys(p.teamShell.squads).length) {
    delete pendingTeam.enabledFeatures?.squads;
  }

  pendingTeam.accounts[p.initialAccountId] = initialTeamAccount;

  await Promise.all([h.Team.add({ doc: pendingTeam }), h.TeamSettings.add({ doc: { id: pendingTeam.id } })]);

  if (pendingTeam.orgId) {
    await joinTeamToOrgOrRemoveTeamFromOrg({
      type: "join",
      orgId: pendingTeam.orgId,
      teamId: pendingTeam.id,
      actionPerformedByAccountId: p.initialAccountId
    });
  }

  if (p.playerToAdd) {
    await team__server__addPlayer({
      player: { ...p.playerToAdd.player, ...{ teamId: pendingTeam.id } }
    });
    let playerBundleId = p.playerBundle?.playerBundle.id;
    if (!playerBundleId) {
      if (p.selectedMemberRole) {
        const id = await playerBundle__server__addPlayerBundle({
          player: { ...p.playerToAdd.player, teamId: pendingTeam.id },
          selfAccountId: p.initialAccountId,
          type:
            p.selectedMemberRole === Team__NonStaffTypes.athlete
              ? PlayerBundle__AccountType.selfAthlete
              : PlayerBundle__AccountType.guardian
        });
        playerBundleId = id;
      }
    }
    if (playerBundleId) {
      await player__server__addBundleLinkToPlayer({
        linkedPlayerBundleId: playerBundleId,
        playerId: p.playerToAdd.player.id
      });
      await updateDerivedForTeam({
        teamId: pendingTeam.id,
        executeImmediate: true
      });
    }
  }

  if (
    pendingTeam.teamSport === TEAM_SPORT.soccer &&
    pendingTeam.teamType === TEAM_TYPES.club &&
    !pendingTeam.orgId &&
    p.initialAccountId
  ) {
    try {
      const account = await h.Account.getDoc(p.initialAccountId);
      if (account) {
        await notifyOfExtremelyImportantError({
          channel: "#ollie-new-club-team",
          message: `New Club Team!
          Name: ${pendingTeam.name}
          Location: ${pendingTeam.country}, ${pendingTeam.province}
          Staff Name: ${account.lastName}, ${account.firstName}
          Staff Email: ${account.email}
          Staff Phone: ${account.phoneNumber || "NONE"}`
        });
      }
    } catch (e) {}
  }

  if (pendingTeam.teamType === TEAM_TYPES.highschool && p.initialAccountId) {
    try {
      const account = await h.Account.getDoc(p.initialAccountId);
      if (!account) {
        throw new Error("No account found for team creation");
      }

      await getServerHelpers().channels.leadsChannel(`:soccer: New High School Team :soccer:
  Name: ${pendingTeam.name}
  Location: ${pendingTeam.country}, ${pendingTeam.province}
  Staff Name: ${account.lastName}, ${account.firstName}
  Staff Email: ${account.email}
  Staff Phone: ${account.phoneNumber || "NONE"}
    `);
    } catch (e) {
      getUniversalHelpers().olliePipe.emitEvent({ type: "error-trouble-sending-high-school-lead", payload: e });
    }
  }
  return pendingTeam;
  // SERVER_ONLY_TOGGLE
}

team__server__createNewTeam.auth = async (r: express.Request) => {
  await validateToken(r);
};

export async function team__server__createNewTeamLegacy(p: { team: Team }) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  // TODO - lots of checks to see if they should be able to create this team

  await Promise.all([
    h.Team.add({ doc: { ...p.team, ...{ disableCovid19Confirm: true, votingMode: MVPVotingMode.standard } } }),
    h.TeamSettings.add({ doc: { id: p.team.id } })
  ]);
  // SERVER_ONLY_TOGGLE
}

team__server__createNewTeamLegacy.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
