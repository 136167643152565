import { TeamId } from "@ollie-sports/models";
import { validateTokenAndEnsureSelfAccountIdMatches } from "../../internal-utils/server-auth";
import * as express from "express";
import { deleteTeam } from "../../internal-utils/team-utils";
import { getUniversalHelpers } from "../../helpers";

export async function team__server__delete(p: { teamId: TeamId; selfAccountId: string }) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const team = await h.Team.getDoc(p.teamId);

  const org = team?.orgId ? await h.Org.getDoc(team.orgId) : null;

  if (
    !team?.accounts[p.selfAccountId]?.additionalPermissions?.manageRolesAndPermissions &&
    !org?.accounts[p.selfAccountId]?.permissions.manageTeams
  ) {
    throw new Error(`User ${p.selfAccountId} is not authorized to delete this team!`);
  }

  await deleteTeam(p);
}
team__server__delete.auth = async (r: express.Request) => {
  await validateTokenAndEnsureSelfAccountIdMatches(r);
};

// i18n certified - complete
