import _ from "lodash";
import { getServerHelpers } from "../../helpers";

export async function team__server__searchTeams(p: { term: string }): Promise<{ teamId: string; name: string }[]> {
  // SERVER_ONLY_TOGGLE
  const { getAppPgPool } = getServerHelpers();
  const matchingTeams: { teamId: string; name: string }[] = [];

  if (p.term.length < 3) {
    return matchingTeams;
  }

  const terms = _.compact(p.term.split(" "));
  const whereClause = `where ${terms.map(term => `item->>'name' ilike '%${term}%'`).join(" and ")}`;

  const teamsQuery = `select id, item->>'name' as name from mirror_team ${whereClause} order by item->>'name' limit 10;`;

  const matches = await (await getAppPgPool().query(teamsQuery)).rows;
  if (matches.length) {
    matches.map(match => {
      matchingTeams.push({
        teamId: match.id,
        name: match.name
      });
    });
  }

  return matchingTeams;
  // SERVER_ONLY_TOGGLE
}

team__server__searchTeams.auth = () => {};

// i18n certified - complete
