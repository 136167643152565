import { TeamId, PlayerId, Team } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import _ from "lodash";

export async function team__client__setPlayerSquadMapping(p: {
  teamId: TeamId;
  newSquadMapping: Record<PlayerId, { a?: true; b?: true; c?: true }>;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  let newVal = _.omitBy(p.newSquadMapping, a => !a);
  newVal = Object.keys(newVal).length ? newVal : h._MagicDeleteValue;

  await h.Team.setPath({
    id: p.teamId,
    pathObj: { squadsPlayerMapping: true },
    value: { squadsPlayerMapping: _.cloneDeep(newVal) }
  });
}

// i18n certified - complete
