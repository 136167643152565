import { CalendarEntry, CalendarEntryId, TeamId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function team__client__getCompletedGamesCount(p: { teamId: TeamId }): Promise<number> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return (
    await h.CalendarEntry.query({
      where: [
        { teamId: ["==", p.teamId] },
        { derived: { endGameDetails: { gameResult: ["in", ["win", "loss", "tie"]] } } } as any
      ],
      limit: 5
    })
  ).docs.length;
}

// i18n certified - complete
