import { Team, Account, TeamId } from "@ollie-sports/models";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";
import { ObjectKeys } from "../../utils";

export async function team__server__getAccountsOnTeam(p: { teamId: TeamId }): Promise<Account[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const team = await h.Team.getDoc(p.teamId);
  if (!team) {
    return [];
  }
  const accountTemp = await h.Account.getDocs(ObjectKeys(team.accounts));
  const accounts: Account[] = [];
  accountTemp.forEach(t => {
    if (t) {
      accounts.push(t);
    }
  });
  return accounts;
  // SERVER_ONLY_TOGGLE
}
team__server__getAccountsOnTeam.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
