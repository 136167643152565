import { TeamId, AccountId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

export async function team__client__setSquadForStaff(p: {
  teamId: TeamId;
  squad: "a" | "b" | "c";
  accountId: AccountId;
  staffActiveOnSquad: boolean;
}) {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const staffSquadValue = p.staffActiveOnSquad ? true : h._MagicDeleteValue;
  await h.Team.update({
    id: p.teamId,
    doc: { accounts: { [p.accountId]: { additionalPermissions: { ["squad_" + p.squad + "_staff"]: staffSquadValue } } } }
  });
}

// i18n certified - complete
