import { AccountId, PlayerId, TeamId } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { BatchTask } from "@ollie-sports/firebase";
import { getUniversalHelpers } from "../../helpers";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";
import { player__server__deleteFromTeam } from "../player";

interface Props {
  teamId: TeamId;
  selfAccountId: AccountId;
  playerIds: PlayerId[];
}

export async function team__server__leaveTeam(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const tasks: BatchTask[] = [];

  for (let i = 0; i < p.playerIds.length; i++) {
    await player__server__deleteFromTeam({ data: { playerId: p.playerIds[i], ignoreUpdateDerivedForTeam: true } });
  }

  // Must wait until player data is actually deleted before running the code to update the team derived code
  await updateDerivedForTeam({ teamId: p.teamId, executeImmediate: true });

  const team = await h.Team.getDoc(p.teamId);

  if (team && team?.accounts[p.selfAccountId]?.exists) {
    await h.Team.update({ id: p.teamId, doc: { accounts: { [p.selfAccountId]: h._MagicDeleteValue } } });
  }

  await h.AccountPrivate.update({
    id: p.selfAccountId,
    doc: {
      settings: {
        teamColors: {
          [p.teamId]: h._MagicDeleteValue
        }
      }
    }
  });
  // SERVER_ONLY_TOGGLE
}
team__server__leaveTeam.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};

// i18n certified - complete
