import { Team } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { getOwnTeamsQueryFragmentV2 } from "../../query-fragments/team.fragments";

export function team__client__getUserTeams(p: { accountId: string }): Promise<Team[]> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  return h.Team.query(getOwnTeamsQueryFragmentV2({ accountId: p.accountId })).then(a => a.docs);
}

// i18n certified - complete
