import { AccountId, Team__Account, TeamId } from "@ollie-sports/models";
import * as express from "express";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";

export async function team__server__setTeamAccount(p: {
  teamId: TeamId;
  accountId: AccountId;
  teamAccount: Team__Account;
  forceRecalculateDerived?: boolean;
}) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  await h.Team.setPath({
    id: p.teamId,
    pathObj: { accounts: { [p.accountId]: true } },
    value: { accounts: { [p.accountId]: p.teamAccount } }
  });
  if (p.forceRecalculateDerived) {
    await updateDerivedForTeam({ executeImmediate: true, teamId: p.teamId });
  }
  // SERVER_ONLY_TOGGLE
}

team__server__setTeamAccount.auth = async (r: express.Request) => {
  // Should have manage role permission
  await validateToken(r);
};

// i18n certified - complete
