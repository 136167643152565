import { Player, School, Team } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { BatchTask } from "@ollie-sports/firebase";
import { getServerHelpers, getUniversalHelpers } from "../../helpers";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";
import { ObjectKeys } from "../../utils/object-keys";

interface Props {
  name: string;
  state: string;
}

export async function team__server__getSchoolSuggestions(p: Props): Promise<School[]> {
  // SERVER_ONLY_TOGGLE
  const q1 = `%${p.name}%`;
  const q2 = p.state;
  const queryParams = [q1, q2];

  const query = `select item
                   from mirror_school
                    where item->>'state' = $2
                    and item->>'name' ilike $1;`;

  const r1 = await getServerHelpers().getAppPgPool().query(query, queryParams);

  return r1.rows.map(r => r.item as School) as School[];
  // SERVER_ONLY_TOGGLE
}
team__server__getSchoolSuggestions.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};

// i18n certified - complete
