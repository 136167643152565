import { Player, Team } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import _ from "lodash";
import { BatchTask } from "@ollie-sports/firebase";
import { getUniversalHelpers } from "../../helpers";
import { updateDerivedForTeam } from "../../internal-utils/team-utils";
import { ObjectKeys } from "../../utils/object-keys";
import { DeepPartial } from "../../utils/type-helpers";

interface Props {
  player: Player;
  updateDerivedForTeam?: boolean;
  autoAddToSquads?: { [key in "a" | "b" | "c"]?: true };
}

export async function team__server__addPlayer(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const tasks: BatchTask[] = [];

  const teamUpdate: DeepPartial<Team> = { derived: { activePlayerIds: { [p.player.id]: true } } };

  if (p.player.linkedPlayerBundleId) {
    teamUpdate.derived!.activePlayerBundleIds = { [p.player.linkedPlayerBundleId]: true };
  }

  if (p.autoAddToSquads) {
    const team = await h.Team.getDoc(p.player.teamId);

    ObjectKeys(p.autoAddToSquads).forEach(squad => {
      if (!team?.squads?.[squad]) {
        throw new Error("Invalid auto add to squad!");
      }

      const nonTypeSafePathSoItMatch = ["squadsPlayerMapping", p.player.id, squad]; //Note, this path should be idential to the type safe path above...
      _.set(teamUpdate, nonTypeSafePathSoItMatch, true);
    });
  }

  tasks.push(await h.Team.update({ id: p.player.teamId, doc: teamUpdate }, { returnBatchTask: true }));
  tasks.push(await h.Player.add({ doc: p.player }, { returnBatchTask: true }));

  await h._BatchRunner.executeBatch(tasks);

  if (p.updateDerivedForTeam) {
    // Must wait until player data is actually deleted before running the code to update the team derived code
    await updateDerivedForTeam({ teamId: p.player.teamId, executeImmediate: true });
  }
  // SERVER_ONLY_TOGGLE
}
team__server__addPlayer.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};

// i18n certified - complete
