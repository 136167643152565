import { Org, Team } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import {
  validateUserIdentity,
  validateSelfAccountId,
  validateTokenAndEnsureSelfAccountIdMatches
} from "../../internal-utils/server-auth";
import { getOwnTeamsQueryFragmentV2 } from "../../query-fragments/team.fragments";
import { org__server__getUserAdminOrgs } from "../org/org__getUserAdminOrgs";
import _ from "lodash";
import { team__client__getUserTeams } from "./team__getUserTeams";
import { account__client__getAccount } from "../account.api";

export async function team__server__getAllTeamsRelevantToUser(p: {
  userAccountId: string;
}): Promise<{ adminOrgs: Org[]; userTeams: Team[]; orgAdminTeams: { [orgId in string]: { org: Org; orgTeams: Team[] } } }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  if (!(await account__client__getAccount({ accountId: p.userAccountId }))) {
    throw new Error("No user with id found: " + p.userAccountId);
  }

  const [userTeams, adminOrgs] = await Promise.all([
    team__client__getUserTeams({ accountId: p.userAccountId }),
    org__server__getUserAdminOrgs(p)
  ]);

  const orgTeams = await Promise.all(
    adminOrgs.map(org => h.Team.query({ where: [{ orgId: ["==", org.id] }, { deletedAtMS: ["==", 0] }] }).then(a => a.docs))
  );

  return {
    adminOrgs,
    userTeams,
    orgAdminTeams: adminOrgs.reduce((acc, org, i) => {
      acc[org.id] = {
        org,
        orgTeams: orgTeams[i]
      };
      return acc;
    }, {} as { [orgId in string]: { org: Org; orgTeams: Team[] } })
  };
  // SERVER_ONLY_TOGGLE
}

team__server__getAllTeamsRelevantToUser.auth = (req: any) => {
  return validateUserIdentity({ propertyToCheck: "userAccountId", request: req });
};

// i18n certified - complete
