import { TeamId, Team } from "@ollie-sports/models";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";
import { ObjectKeys } from "../../utils";
import { common__extractTeamNameWithSquadFromTeamAndTeamIdWithSquad, common__generateTeamIdWithSquad } from "../common.api";
import { compute } from "../..";

export async function team__server__getTeamNames(p: {
  teamIds: TeamId[];
}): Promise<{ teamId: TeamId; name: string; teamIdWithSquad: string }[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const teamsTemp = await h.Team.getDocs(p.teamIds);
  const teamNames: { teamId: TeamId; name: string; teamIdWithSquad: string }[] = [];
  teamsTemp.forEach(team => {
    if (team) {
      teamNames.push({ teamId: team.id, name: team.name, teamIdWithSquad: common__generateTeamIdWithSquad(team.id) });
      if (team.enabledFeatures?.squads && team.squads) {
        compute.team.teamExtractSquadKeys(team.squads).forEach(squad => {
          const teamIdWithSquad = common__generateTeamIdWithSquad(team.id, squad);
          teamNames.push({
            teamId: team.id,
            teamIdWithSquad,
            name: common__extractTeamNameWithSquadFromTeamAndTeamIdWithSquad(teamIdWithSquad, team, true)
          });
        });
      }
    }
  });
  return teamNames;
  // SERVER_ONLY_TOGGLE
}
team__server__getTeamNames.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
