import { TeamId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

const IMAGE_DIRECTORY = "team_photos/";

export async function team__client__deleteTeamPhoto(p: { teamId: TeamId }) {
  const { app, ollieFirestoreV2: h } = getUniversalHelpers();
  const team = await h.Team.getDoc(p.teamId);
  if (!team) {
    return;
  }

  if (team.teamPhotoUri) {
    try {
      await h.Team.update({ id: p.teamId, doc: { teamPhotoUri: h._MagicDeleteValue } });
      const deleteTask = app.storage().refFromURL(team.teamPhotoUri).delete();
      await deleteTask;
    } catch (e) {
      console.warn("Error deleting team photo images");
    }
  }
}

// i18n certified - complete
