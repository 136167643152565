import { Player, Team, TeamId } from "@ollie-sports/models";
import { validateToken } from "../../internal-utils/server-auth";
import * as express from "express";
import { BatchTask } from "@ollie-sports/firebase";
import { getUniversalHelpers } from "../../helpers";
import _ from "lodash";
import { ObjectKeys } from "../../utils/object-keys";

interface Props {
  players: Player[];
  teamId: TeamId;
  autoAddToSquads?: { [key in "a" | "b" | "c"]?: true };
}

export async function team__server__addPlayers(p: Props) {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const tasks: BatchTask[] = [];

  const team = await h.Team.getDoc(p.teamId);

  if (!team) {
    throw new Error("Invalid team id!");
  }

  p.players.forEach(async player => {
    tasks.push(await h.Player.add({ doc: player }, { returnBatchTask: true }));
  });

  const { activePlayerIds, activePlayerBundleIds } = p.players.reduce(
    (acc, pl) => {
      acc.activePlayerIds[pl.id] = true;
      if (pl.linkedPlayerBundleId) {
        acc.activePlayerBundleIds[pl.linkedPlayerBundleId] = true;
      }
      return acc;
    },
    { activePlayerBundleIds: {} as Record<string, true>, activePlayerIds: {} as Record<string, true> }
  );

  const teamUpdate: Partial<Team> = { derived: { activePlayerIds, activePlayerBundleIds } };

  if (p.autoAddToSquads) {
    ObjectKeys(p.autoAddToSquads).forEach(squad => {
      if (!team.squads?.[squad]) {
        throw new Error("Invalid auto add squad!");
      }
      for (let i = 0; i < p.players.length; i++) {
        const playerId = p.players[i].id;
        const nonTypeSafePathSoItMatch = ["squadsPlayerMapping", playerId, squad]; //Note, this path should be idential to the type safe path above...
        _.set(teamUpdate, nonTypeSafePathSoItMatch, true);
      }
    });
  }

  tasks.push(await h.Team.update({ id: p.teamId, doc: teamUpdate }, { returnBatchTask: true }));

  await h._BatchRunner.executeBatch(tasks);
  // SERVER_ONLY_TOGGLE
}
team__server__addPlayers.auth = async (r: express.Request) => {
  // Make sure user has correct team permission
  await validateToken(r);
};

// i18n certified - complete
