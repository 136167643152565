import { TeamId, PrettyPlayer } from "@ollie-sports/models";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";
import moment from "moment";
import { fetchPrettyPlayerList } from "../../internal-utils/player-utils";

export async function team__server__getRecentGuestPlayers(p: { teamId: TeamId }): Promise<PrettyPlayer[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const earliestGuestPlayerMS = moment().subtract(1, "year").valueOf();

  const players = (
    await h.Player.query({
      where: [{ teamId: ["==", p.teamId] }, { lastTimeAddedAsGuestPlayerMS: [">", earliestGuestPlayerMS] }]
    })
  ).docs;

  return fetchPrettyPlayerList(players.filter(player => player.deletedAtMS > 0));
  // SERVER_ONLY_TOGGLE
}

team__server__getRecentGuestPlayers.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
