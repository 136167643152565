import { getUniversalHelpers } from "../../helpers";
import { AccountId, TeamId } from "@ollie-sports/models";
import { account } from "../../compute";
import shortid from "shortid";

const IMAGE_DIRECTORY = "team_logos/";

export async function team__client__uploadLogo(p: { blob: any; teamId: TeamId }): Promise<string | undefined> {
  const { app, ollieFirestoreV2: h } = getUniversalHelpers();

  const team = await h.Team.getDoc(p.teamId);
  if (!team) {
    return;
  }

  // Try to delete the old profile images to save storage. If something goes wrong, just continue.
  // We don't want to stop uploading the profile image because the old one can't be deleted.
  try {
    if (team.logoUri) {
      const deleteTask = app.storage().refFromURL(team.logoUri).delete();
      await deleteTask;
    }
  } catch (e) {
    console.warn("Error deleting old team logo");
  }

  const uploadTask = app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + p.teamId + "/" + shortid() + ".jpg")
    .put(p.blob);

  // tslint:disable-next-line: await-promise
  await uploadTask;

  const storageUri = await uploadTask.snapshot.ref.getDownloadURL();

  return storageUri as string;
}

export async function team__client__uploadLogoNewTeam(p: { blob: any; uniqueString: string }): Promise<string | undefined> {
  const { app, ollieFirestoreV2: h } = getUniversalHelpers();

  const uploadTask = app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + p.uniqueString + "/" + shortid() + ".jpg")
    .put(p.blob);

  // tslint:disable-next-line: await-promise
  await uploadTask;

  const storageUri = await uploadTask.snapshot.ref.getDownloadURL();

  return storageUri as string;
}

// i18n certified - complete
