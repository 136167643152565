import { TeamId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";

const IMAGE_DIRECTORY = "team_photos/";

export async function team__client__uploadTeamPhoto(p: { blob: any; teamId: TeamId }) {
  const { app, ollieFirestoreV2: h } = getUniversalHelpers();
  const team = await h.Team.getDoc(p.teamId);
  if (!team) {
    return;
  }

  // Try to delete the old team photo to save storage. If something goes wrong, just continue.
  // We don't want to stop uploading the team photo because the old one can't be deleted.
  try {
    if (team.teamPhotoUri) {
      const deleteTask = app.storage().refFromURL(team.teamPhotoUri).delete();
      await deleteTask;
    }
  } catch (e) {
    console.warn("Error deleting team photo images");
  }
  const task = app
    .storage()
    .ref()
    .child(IMAGE_DIRECTORY + Date.now() + p.teamId)
    .put(p.blob);

  return task;
}

// i18n certified - complete
