import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useTeam } from "../../hooks/useTeam";
import { Box, SvgIcon, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import { View } from "react-native-web";
import { CenteredLoader } from "../../components/CenteredLoader";
import { getCurrentLocale, translate } from "@ollie-sports/i18n";
import {
  Account,
  Org,
  OrgRegistrationStatus,
  PlayerBundle__AccountType,
  PlayerId,
  PrettyPlayer,
  Team,
  Team__Account
} from "@ollie-sports/models";
import CoolerTable from "../../components/CoolerTable";
import { COLORS, ObjectKeys, PRETTY_ORG_REGISTRATION_STATUS, Team__StaffPresets, compute } from "@ollie-sports/core";
import { useAccounts } from "../../hooks/useAccounts";
import _ from "lodash";
import { StyledText } from "../../components/StyledText";
import { ProfileCircle } from "../../components/ProfileCircle";
import { useState } from "react";
import { getBifrost } from "../../services/bifrost.service";
import { ClipboardIcon, HomeIcon, PencilSquareIcon, TrashIcon, UserCircleIcon, UserIcon } from "@heroicons/react/24/outline";
import { MoreHorizontal } from "react-feather";
import { PermissionRow } from "../../components/PermissionRow";
import { openGuardianStaffEditModal } from "./GuardianStaffEditModal";
import { TeamHome } from "./TeamHome";
import { CoolTextInput } from "../../components/Inputs/CoolTextInput";
import { getCurrentUserAccountId, useCurrentUserTeamsAndOrgs } from "../../hooks/commonDataUtils";
import { useOrg } from "../../hooks/useOrg";
import { CoolerTabbedContent } from "../../components/CoolerTabbedContent";
import { EditButton } from "../../components/EditButton";
import { openPlayerEditModal } from "./PlayerEditModal";
import getConfirm from "../../components/modals/getConfirm";
import { openLoadingIndicator } from "../../utils/openLoadingIndicator";
import { openErrorToast, openSuccessToast } from "../../utils/openErrorToast";
import { ActionButtonDropdown } from "../../components/ActionButtonDropdown";
import { BackButton } from "../../components/BackButton";
import { useSearchParamsState } from "../../hooks/useSearchParamsState";
import { useOrgSeasons } from "../../hooks/useOrgSeasons";

export default function TeamDetails() {
  const { teamId, orgId } = useParams<any>();

  const { team, isLoading } = useTeam({ teamId });
  const { org, isLoading: isLoading2 } = useOrg({ orgId, enabled: !!orgId });

  return (
    <Box px={3} py={2} display="flex" style={{ flex: 1 }}>
      <View style={{ flex: 1 }}>
        {isLoading || isLoading2 ? (
          <CenteredLoader />
        ) : team && !isLoading2 ? (
          <TeamDetailsInner org={org} team={team} />
        ) : (
          <Typography>{translate({ defaultMessage: "Failed to load team" })}</Typography>
        )}
      </View>
    </Box>
  );
}

function TeamDetailsInner(p: { team: Team; org?: Org }) {
  const accountIds = ObjectKeys(p.team.accounts);
  const { accounts } = useAccounts({
    accountIds
  });

  const guardianAccounts = accounts?.filter(account => p.team.accounts?.[account.id]?.roles.guardian);
  const staffAccounts = accounts?.filter(account => p.team.accounts?.[account.id]?.roles.staff);
  const { data: players } = getBifrost().player__client__playersOnATeamSubscription.useClientSubscription({
    teamId: p.team.id
  });

  const [searchParams, setSearchParams] = useSearchParamsState<{ tab?: string }>();

  const history = useHistory();

  return (
    <View style={{ flex: 1 }}>
      <BackButton />

      <h1 className="text-2xl sm:text-4xl mt-4">{p.team.name}</h1>

      <CoolerTabbedContent
        className="py-4 pr-6"
        useQueryParams
        initialTabKey={searchParams.tab}
        onTabChange={selected => {
          history.replace(window.location.pathname.split("/").slice(0, -1).concat(selected).join("/"));
        }}
        tabs={[
          {
            label: translate.common.Home,
            key: "home",
            labelIcon: <HomeIcon />,
            getContents: () => <TeamHome team={p.team} org={p.org} />
          },
          {
            label: `${translate.common.Players}${players?.length ? ` (${players.length})` : ""}`,
            key: "players",
            labelIcon: <UserCircleIcon />,
            getContents: () => <PlayersTable players={players ?? []} team={p.team} org={p.org} />
          },
          {
            label: `${translate.common.Guardians}${guardianAccounts.length ? ` (${guardianAccounts.length})` : ""}`,
            key: "guardian",
            labelIcon: <UserIcon />,
            getContents: () => (
              <TeamAccountTable
                type={"guardian"}
                accountsForTable={guardianAccounts}
                team={p.team}
                players={players ?? []}
                org={p.org}
              />
            )
          },
          {
            label: `${translate.common.Staff}${staffAccounts.length ? ` (${staffAccounts.length})` : ""}`,
            key: "staff",
            labelIcon: <ClipboardIcon />,
            getContents: () => (
              <TeamAccountTable
                type={"staff"}
                accountsForTable={staffAccounts}
                team={p.team}
                players={players ?? []}
                org={p.org}
              />
            )
          }
        ]}
      />
    </View>
  );
}

function TeamAccountTable(p: {
  type: "guardian" | "staff";
  accountsForTable: Account[];
  team: Team;
  players: PrettyPlayer[];
  org?: Org;
}) {
  const guardianPlayerMap: Record<string, PrettyPlayer[]> = {};
  const [searchInput, setSearchInput] = useState("");
  const { data: teamsAndOrgs } = useCurrentUserTeamsAndOrgs();

  const isStaffOrOrgAdmin = compute.isStaffOrOrgAdmin({
    accountId: getCurrentUserAccountId(),
    team: p.team,
    org: teamsAndOrgs?.orgs.find(o => o.id === p.team.orgId)
  });

  p.accountsForTable
    .map(account => account.id)
    .forEach(guardianId => {
      guardianPlayerMap[guardianId] = [];
      p.players.forEach(pl => {
        if (pl.derived.accountInfoSource === "account" || pl.derived.accountInfoSource === "playerBundle") {
          if (pl.derived.playerBundle.managingAccounts?.[guardianId]) {
            const arr = guardianPlayerMap[guardianId];
            if (arr) {
              arr.push(pl);
            }
          }
        }
      });
    });

  function getOrderedPlayers(prettyPlayers: PrettyPlayer[]) {
    const orderedPlayers = _.orderBy(
      prettyPlayers,
      a => `${a.derived.accountInfo.firstName} ${a.derived.accountInfo.lastName}`,
      "asc"
    );
    return orderedPlayers;
  }

  return (
    <CoolerTable
      noItemsMessage={translate(
        {
          defaultMessage: "No {type} on this team yet",
          description: "Telling the user there are no people on a team for a specific type (player, guardian, staff)"
        },
        {
          type: p.type === "guardian" ? translate.common.Guardians : translate.common.Staff
        }
      )}
      filters={[
        {
          filterComponent: (
            <CoolTextInput
              onChange={newVal => {
                setSearchInput(newVal ?? "");
              }}
              value={searchInput}
              showClearButton
              inputProps={{
                placeholder: translate({ defaultMessage: "Search by name..." })
              }}
            />
          ),
          onFilter(items) {
            return searchInput
              ? items.filter(item =>
                  `${item.firstName}${item.lastName}`
                    .toLowerCase()
                    .replaceAll(" ", "")
                    .includes(searchInput.toLowerCase().replaceAll(" ", ""))
                )
              : items;
          }
        }
      ]}
      getRowHref={
        p.org
          ? item => {
              return `/app/org/${p.team.orgId}/teams/${p.team.id}/${p.type}/members/${item.id}`;
            }
          : undefined
      }
      items={p.accountsForTable}
      defaultSortSettings={{
        label: translate.common.Name,
        dir: "asc"
      }}
      rowButtons={
        isStaffOrOrgAdmin
          ? [
              {
                getLabel: () => translate.common.Edit,
                type: "inline",
                getIcon: () => <PencilSquareIcon color={COLORS.blue_66} />,
                async onClick(item) {
                  openGuardianStaffEditModal({
                    account: item,
                    team: p.team
                  });
                }
              }
            ]
          : []
      }
      columnDefs={[
        {
          label: "",
          getCellCustomClassName: () => "w-16",
          headerCustomClassName: "w-16",
          getValue(item) {
            return <ProfileCircle accountInfo={item} size={40} />;
          }
        },
        {
          label: translate.common.Name,
          getValue(item) {
            return `${item.firstName} ${item.lastName}`;
          },
          sortItems(items, dir) {
            return _.orderBy(items, a => `${a.firstName} ${a.lastName}`, dir);
          }
        },
        p.type === "staff"
          ? {
              label: translate.common.Role,
              getValue(item) {
                const teamAccount = p.team.accounts[item.id];
                if (teamAccount && teamAccount.staffTitle) {
                  return Team__StaffPresets(getCurrentLocale())[teamAccount.staffTitle].staffTitle;
                }
                return "";
              }
            }
          : null,
        {
          label: translate.common.Email,
          getValue(item) {
            return item.email;
          },
          sortItems(items, dir) {
            return _.orderBy(items, a => a.email, dir);
          }
        },
        {
          label: translate.common.PhoneNumber,
          getValue(item) {
            return item.phoneNumber;
          }
        },
        {
          label: translate.common.Player,
          getValue(item) {
            const players = guardianPlayerMap[item.id];
            const orderedPlayers = getOrderedPlayers(players);
            return (
              <View>
                {orderedPlayers.map(player => {
                  return (
                    <StyledText
                      style={{ marginBottom: 8 }}
                    >{`${player.derived.accountInfo.firstName} ${player.derived.accountInfo.lastName}`}</StyledText>
                  );
                })}
              </View>
            );
          },
          sortItems(items, dir) {
            return _.orderBy(
              items,
              a => {
                const players = guardianPlayerMap[a.id];
                const orderedPlayers = getOrderedPlayers(players);
                return orderedPlayers
                  .map(player => `${player.derived.accountInfo.firstName} ${player.derived.accountInfo.lastName}`)
                  .join("");
              },
              dir
            );
          }
        },
        {
          label: translate.common.Permissions,
          getValue(item) {
            const teamAccount = p.team.accounts[item.id];
            if (!teamAccount) {
              return null;
            }
            return <PermissionRow teamAccount={teamAccount} />;
          }
        }
      ]}
      getItemKey={item => item.id}
    />
  );
}

function PlayersTable(p: { team: Team; players: PrettyPlayer[]; org?: Org }) {
  const [selectedPlayerIds, setSelectedPlayerIds] = useState<Record<PlayerId, true>>({});
  const [searchInput, setSearchInput] = useState("");
  const playerAccountIds = _.compact(
    p.players.map(pp => {
      return pp.derived.accountInfoSource !== "player"
        ? compute.playerBundle.extractSelfAccountIdFromPlayerBundle({ bundle: pp.derived.playerBundle })
        : null;
    })
  );
  const { data: teamsAndOrgs } = useCurrentUserTeamsAndOrgs();

  const isStaffOrOrgAdmin = compute.isStaffOrOrgAdmin({
    accountId: getCurrentUserAccountId(),
    team: p.team,
    org: teamsAndOrgs?.orgs.find(o => o.id === p.team.orgId)
  });
  const { accounts: playerAccounts } = useAccounts({ accountIds: playerAccountIds });

  const orgSeasons = useOrgSeasons({ orgId: p.team.orgId ?? "" });

  const currentOrgSeasons = orgSeasons?.filter(os => compute.orgSeason.isCurrentOrgSeason(os));

  const playerBundleIds = _.compact(
    p.players?.map(pp => (pp.derived.accountInfoSource === "player" ? null : pp.derived.playerBundle.id)) ?? []
  );

  const { data: orgRegistrationData } =
    getBifrost().orgRegistration__server__getOrgRegistrationDataForListOfPlayerBundleIdsInOrgSeasons.useServer(
      {
        orgId: p.team.orgId ?? "",
        orgSeasonIds: currentOrgSeasons?.map(a => a.id) ?? [],
        playerBundleIds,
        teamId: p.team.id
      },
      { enabled: !!p.team.orgId && !!currentOrgSeasons?.length && !!playerBundleIds.length }
    );
  return (
    <CoolerTable
      selectRowOptions={
        isStaffOrOrgAdmin
          ? {
              onUpdate(items) {
                setSelectedPlayerIds(items);
                console.log(items);
              },
              selectedItemsByKey: selectedPlayerIds
            }
          : undefined
      }
      defaultCellClassName="align-middle"
      filters={[
        {
          filterComponent: (
            <CoolTextInput
              onChange={newVal => {
                setSearchInput(newVal ?? "");
              }}
              value={searchInput}
              showClearButton
              inputProps={{
                placeholder: translate({ defaultMessage: "Search by name..." })
              }}
            />
          ),
          onFilter(items) {
            return searchInput
              ? items.filter(item =>
                  `${item.prettyPlayer.derived.accountInfo.firstName}${item.prettyPlayer.derived.accountInfo.lastName}`
                    .toLowerCase()
                    .replaceAll(" ", "")
                    .includes(searchInput.toLowerCase().replaceAll(" ", ""))
                )
              : items;
          }
        },
        isStaffOrOrgAdmin
          ? {
              filterComponent: (
                <ActionButtonDropdown
                  style={{ marginLeft: "auto" }}
                  actions={[
                    {
                      key: "delete",
                      label: () => translate({ defaultMessage: "Remove Players" }),
                      onClick: async () => {
                        const confirm = await getConfirm({
                          confirmButtonColor: "red",
                          subtitle:
                            "Are you sure you want to remove these players? Removing the players from the roster will also remove any attached parent/guardian accounts from the team unless those parents/guardians are staff members or have another child on the team.",
                          title: translate(
                            { defaultMessage: "Remove {numPlayers} Players?" },
                            { numPlayers: Object.keys(selectedPlayerIds).length }
                          )
                        });
                        if (confirm) {
                          const loadingIndicator = openLoadingIndicator();
                          try {
                            await getBifrost().player__server__deletePlayersFromTeam.fetchServer({
                              playerIds: Object.keys(selectedPlayerIds),
                              teamId: p.team.id
                            });
                            openSuccessToast();
                          } catch (e) {
                            openErrorToast(translate.common.SomethingWentWrong);
                          }
                          loadingIndicator.close();
                        }
                      }
                    }
                  ]}
                  disabled={!Object.keys(selectedPlayerIds).length}
                >
                  <span style={{ fontWeight: "normal", marginRight: 6 }}>{translate.common.Actions}</span>
                  <SvgIcon style={{ paddingRight: 6 }}>
                    <MoreHorizontal />
                  </SvgIcon>
                </ActionButtonDropdown>
              ),
              onFilter(items) {
                return items;
              }
            }
          : null
      ]}
      noItemsMessage={translate(
        {
          defaultMessage: "No {type} on this team yet",
          description: "Telling the user there are no people on a team for a specific type (player, guardian, staff)"
        },
        {
          type: translate.common.Players
        }
      )}
      getRowHref={
        p.org && isStaffOrOrgAdmin
          ? item => {
              let accountIdOrgPlayerBundleId = "";
              if (item.prettyPlayer.derived.accountInfoSource === "account") {
                accountIdOrgPlayerBundleId =
                  Object.keys(item.prettyPlayer.derived.playerBundle.managingAccounts ?? {}).find(
                    aId =>
                      item.prettyPlayer.derived.accountInfoSource === "account" &&
                      item.prettyPlayer.derived.playerBundle.managingAccounts?.[aId]?.type ===
                        PlayerBundle__AccountType.selfAthlete
                  ) ?? item.prettyPlayer.derived.playerBundle.id;
              } else if (item.prettyPlayer.derived.accountInfoSource === "playerBundle") {
                accountIdOrgPlayerBundleId = item.prettyPlayer.derived.playerBundle.id;
              }
              if (!accountIdOrgPlayerBundleId) {
                return null;
              }
              return `/app/org/${p.team.orgId}/teams/${p.team.id}/players/members/${accountIdOrgPlayerBundleId}`;
            }
          : undefined
      }
      rowButtons={
        isStaffOrOrgAdmin
          ? [
              {
                getLabel: () => translate({ defaultMessage: "Remove From Roster" }),
                onClick: async item => {
                  const confirm = await getConfirm({
                    title: translate(
                      { defaultMessage: `Remove {name} from Roster?` },
                      { name: item.prettyPlayer.derived.accountInfo.firstName }
                    ),
                    subtitle: translate({
                      defaultMessage:
                        "Removing the player from the roster will also remove any attached parent/guardian accounts from the team unless those parents/guardians are staff members or have another child on the team."
                    }),
                    cancelText: translate.common.Cancel,
                    confirmText: translate.common.Remove,
                    confirmButtonColor: "red"
                  });
                  if (confirm) {
                    const loader = openLoadingIndicator();
                    try {
                      await getBifrost().player__server__deleteFromTeam.fetchServer({
                        data: { playerId: item.prettyPlayer.player.id }
                      });
                      openSuccessToast(
                        translate({
                          defaultMessage: `Removed ${item.prettyPlayer.derived.accountInfo.firstName} ${item.prettyPlayer.derived.accountInfo.lastName}`
                        })
                      );
                    } catch (e) {
                      openErrorToast(
                        translate({
                          defaultMessage:
                            "There was a problem removing the player. Please try again or contact support@olliesports.com"
                        })
                      );
                    }
                    loader.close();
                  }
                },
                type: "inline",
                getIcon: () => <TrashIcon color={COLORS.red} />
              },
              {
                getLabel: () => translate.common.Edit,
                type: "inline",
                onClick: async item => {
                  openPlayerEditModal({
                    player: item.prettyPlayer,
                    team: p.team,
                    playerAccount: item.playerAccount
                  });
                },
                getIcon: () => <PencilSquareIcon color={COLORS.blue} />
              }
            ]
          : []
      }
      items={p.players.map(pp => {
        const playerAccountId =
          pp.derived.accountInfoSource !== "player"
            ? compute.playerBundle.extractSelfAccountIdFromPlayerBundle({ bundle: pp.derived.playerBundle })
            : undefined;
        const playerAccount = playerAccountId ? playerAccounts.find(acc => acc.id === playerAccountId) : undefined;
        const allStatuses = Object.values(orgRegistrationData ?? {}).map(a => {
          const playerData = a.playerData.find(
            b => pp.derived.accountInfoSource !== "player" && b.playerBundleId === pp.derived.playerBundle.id
          );
          return playerData?.status ?? OrgRegistrationStatus["no-registration-needed"];
        });
        const registrationStatus = compute.orgRegistration.getPlayerRegistrationStatus(allStatuses);
        return { prettyPlayer: pp, playerAccount, registrationStatus };
      })}
      defaultSortSettings={{
        label: translate.common.Name,
        dir: "asc"
      }}
      columnDefs={[
        {
          label: "",
          getValue(item) {
            return <ProfileCircle accountInfo={item.prettyPlayer.derived.accountInfo} size={40} />;
          },
          getCellCustomClassName: () => "w-16",
          headerCustomClassName: "w-16"
        },
        {
          label: translate.common.Name,
          getValue(item) {
            return `${item.prettyPlayer.derived.accountInfo.firstName} ${item.prettyPlayer.derived.accountInfo.lastName}`;
          },
          sortItems(items, dir) {
            return _.orderBy(
              items,
              a => `${a.prettyPlayer.derived.accountInfo.firstName} ${a.prettyPlayer.derived.accountInfo.lastName}`,
              dir
            );
          }
        },
        {
          label: translate({ defaultMessage: "Registration Status" }),
          getValue(item) {
            return item.prettyPlayer.derived.accountInfoSource === "player"
              ? translate({ defaultMessage: "No Account Linked" })
              : PRETTY_ORG_REGISTRATION_STATUS(getCurrentLocale())[item.registrationStatus];
          },
          sortItems(items, dir) {
            return _.orderBy(items, a => PRETTY_ORG_REGISTRATION_STATUS(getCurrentLocale())[a.registrationStatus], dir);
          }
        },
        {
          label: translate.common.JerseyNumber,
          getValue(item) {
            return item.prettyPlayer.player.jerseyNumber ?? "";
          },
          sortItems(items, dir) {
            return _.orderBy(
              items,
              a => (a.prettyPlayer.player.jerseyNumber ? parseInt(a.prettyPlayer.player.jerseyNumber) : -1),
              dir
            );
          }
        },
        {
          label: translate.common.Permissions,
          getValue(item) {
            let teamAccount: Team__Account | undefined = undefined;
            if (item.prettyPlayer.derived.accountInfoSource === "account") {
              const playerAccountId = ObjectKeys(item.prettyPlayer.derived.playerBundle.managingAccounts ?? {}).find(
                accountId =>
                  item.prettyPlayer.derived.accountInfoSource === "account" &&
                  item.prettyPlayer.derived.playerBundle.managingAccounts?.[accountId]?.type ===
                    PlayerBundle__AccountType.selfAthlete
              );
              teamAccount = playerAccountId ? p.team.accounts?.[playerAccountId] : undefined;
            }
            if (!teamAccount) {
              return null;
            }
            return <PermissionRow teamAccount={teamAccount} />;
          }
        }
      ]}
      getItemKey={item => item.prettyPlayer.player.id}
    />
  );
}
