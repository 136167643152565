import { Account, Player, PrettyPlayer, Team, Team__Account, Team__StaffTypes } from "@ollie-sports/models";
import { openModal } from "../../components/modals/imperativeModal";
import { FullScreenModal } from "../../components/modals/getFullscreenModal";
import { TouchableOpacity, View } from "react-native-web";
import { translate } from "@ollie-sports/i18n";
import { openErrorToast, openSuccessToast } from "../../utils/openErrorToast";
import { useEffect, useRef, useState } from "react";
import { dequal } from "dequal";
import { COLORS, ObjectKeys, api, compute } from "@ollie-sports/core";
import { getBifrost } from "../../services/bifrost.service";
import getConfirm from "../../components/modals/getConfirm";
import getAlert from "../../components/modals/getAlert";
import { TeamAccountEdit } from "./TeamAccountEdit";
import { Form, PrettyCoolTextInputWithLabel } from "../../components/Form";
import { useImmutableState } from "../../utils/useImmutableState";
import { StyledText } from "../../components/StyledText";
import { ShadowView } from "../../components/ShadowView";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import { downscaleImage, fileToBase64 } from "../../utils/fileUtils";
import { openLoadingIndicator } from "../../utils/openLoadingIndicator";
import { CameraIcon } from "@heroicons/react/20/solid";
import { SingleImageDropZone } from "../../components/DropZone";
import { useHistory } from "react-router";

type Props = {
  player: PrettyPlayer;
  playerAccount?: Account;
  team: Team;
};

export function openPlayerEditModal(p: Props) {
  const initalTeamAccount = p.playerAccount ? p.team.accounts?.[p.playerAccount?.id] : undefined;
  return new Promise<void>(res => {
    const modal = openModal({
      body: (
        <PlayerEditModal
          {...p}
          initialTeamAccount={initalTeamAccount}
          onRequestDismiss={() => {
            modal.close();
            res();
          }}
        />
      )
    });
  });
}

function PlayerEditModal(p: Props & { initialTeamAccount?: Team__Account; onRequestDismiss: () => void }) {
  const [teamAccount, setTeamAccount] = useState<Team__Account | undefined>(p.initialTeamAccount);
  const getInitPlayerDetails = () => ({
    ...p.player.derived.accountInfo,
    jerseyNumber: p.player.player.jerseyNumber
  });

  const [isDeleting, setIsDeleting] = useState(false);
  const [playerDetails, setPlayerDetails] = useImmutableState(getInitPlayerDetails());
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const INPUT_ID = useRef(Math.random() + "").current;

  const history = useHistory();

  async function handleImage(file: File) {
    try {
      if (file) {
        const base64 = await fileToBase64(file);
        const { blob } = await downscaleImage(base64, 512, true);
        const { blob: blobSmall } = await downscaleImage(base64, 128, true);
        if (blob && blobSmall) {
          let url = URL.createObjectURL(blob);
          let urlSmall = URL.createObjectURL(blobSmall);
          setPlayerDetails({ profileImageUri: url, profileImageUriSmall: urlSmall });
        }
      }
    } catch (e) {
      openErrorToast(
        translate({
          defaultMessage: "There was a problem selecting the image. Please try again or contact support@olliesports.com"
        })
      );
    }
  }

  return (
    <Form
      children={isFormValid => {
        return (
          <FullScreenModal
            onRequestDismiss={async () => {
              if (
                isDeleting ||
                (((p.initialTeamAccount && !dequal(teamAccount, p.initialTeamAccount)) ||
                  !dequal(getInitPlayerDetails(), playerDetails)) &&
                  !window.confirm(translate({ defaultMessage: "You have unsaved changes. Are you sure you wish to leave?" })))
              ) {
                return;
              }
              p.onRequestDismiss();
            }}
            title={`${p.player.derived.accountInfo.firstName} ${p.player.derived.accountInfo.lastName}`}
            bottomButton={{
              title: translate.common.Save,
              enabled: isFormValid && !isDeleting,
              onPress: async () => {
                try {
                  // Update the player/playerbundle/account
                  let updatedPlayer: Player = {
                    ...p.player.player,
                    jerseyNumber: playerDetails.jerseyNumber,
                    virtualAthleteAccount: {
                      ...p.player.player.virtualAthleteAccount,
                      firstName: playerDetails.firstName,
                      lastName: playerDetails.lastName
                    }
                  };

                  // Changed profile picture
                  if (playerDetails.profileImageUri !== p.player.derived.accountInfo.profileImageUri) {
                    if (!playerDetails.profileImageUri) {
                      // Delete profile photo
                      updatedPlayer.virtualAthleteAccount.profileImageUri = api.common__magicDeleteValue();
                      updatedPlayer.virtualAthleteAccount.profileImageUriSmall = api.common__magicDeleteValue();
                    } else {
                      const response = await fetch(playerDetails.profileImageUri);
                      const blob = await response.blob();
                      const responseSmall = await fetch(playerDetails.profileImageUri);
                      const blobSmall = await responseSmall.blob();
                      if (p.player.derived.accountInfoSource === "player") {
                        const { data: imageData } = await getBifrost().player__client__uploadProfileImage.fetchClient({
                          blob,
                          blobSmall,
                          playerId: p.player.player.id
                        });
                        if (imageData) {
                          updatedPlayer.virtualAthleteAccount.profileImageUri = imageData.uri;
                          updatedPlayer.virtualAthleteAccount.profileImageUriSmall = imageData.uriSmall;
                        }
                      } else if (p.player.derived.accountInfoSource === "playerBundle") {
                        if (p.player.derived.playerBundle) {
                          const { data: imageData } = await getBifrost().playerBundle__client__uploadProfileImage.fetchClient({
                            blob,
                            blobSmall,
                            playerBundleId: p.player.derived.playerBundle.id
                          });
                          if (imageData) {
                            updatedPlayer.virtualAthleteAccount.profileImageUri = imageData.uri;
                            updatedPlayer.virtualAthleteAccount.profileImageUriSmall = imageData.uriSmall;
                          }
                        }
                      } else {
                        if (p.playerAccount) {
                          const { data: imageData } = await getBifrost().account__client__uploadProfileImage.fetchClient({
                            blob,
                            blobSmall,
                            accountId: p.playerAccount.id
                          });
                          if (imageData) {
                            updatedPlayer.virtualAthleteAccount.profileImageUri = imageData.uri;
                            updatedPlayer.virtualAthleteAccount.profileImageUriSmall = imageData.uriSmall;
                          }
                        }
                      }
                    }
                  }
                  await getBifrost().player__server__updatePlayerInfoForTeam.fetchServer({
                    initialPrettyPlayer: p.player,
                    updatedPlayer: updatedPlayer,
                    teamAccount,
                    playerSelfAccountId: p.playerAccount?.id
                  });

                  // Update the team account
                  if (p.initialTeamAccount && teamAccount && p.playerAccount) {
                    const headCoachLimit = compute.team.teamExtractSquadKeys(p.team.squads).length || 1;
                    if (
                      teamAccount.staffTitle === Team__StaffTypes.headCoach &&
                      p.initialTeamAccount.staffTitle !== Team__StaffTypes.headCoach &&
                      (
                        ObjectKeys(p.team.accounts).filter(
                          id =>
                            p.playerAccount &&
                            p.team.accounts[id]?.staffTitle === Team__StaffTypes.headCoach &&
                            id !== p.playerAccount.id
                        ) ?? []
                      ).length >= headCoachLimit
                    ) {
                      getAlert({
                        subtitle: `${translate(
                          {
                            defaultMessage: `Your team can only have {headCoachLimit} head {headCoachLimit, plural, zero {coaches} one {coach} other {coaches}}.`
                          },
                          { headCoachLimit }
                        )} ${translate(
                          {
                            defaultMessage: `If you need more than {headCoachLimit} head {headCoachLimit, plural, zero {coaches} one {coach} other {coaches}}, please contact us at {supportEmail}`
                          },
                          { supportEmail: "support@olliesports.com", headCoachLimit }
                        )}`,
                        title: translate({
                          defaultMessage: "Too Many Head Coaches",
                          description: "Alert title warning user about too many head coaches"
                        })
                      });
                      return;
                    }
                    if (teamAccount.roles.staff && !compute.team.hasAnyTeamPermission({ teamAccount: teamAccount })) {
                      const confirm = await getConfirm({
                        title: translate({ defaultMessage: "Staff with no permissions" }),
                        subtitle: translate({
                          defaultMessage:
                            "You are trying to save a staff member but they don't have any permissions. Are you sure you want to continue?"
                        }),
                        cancelText: translate.common.Cancel,
                        confirmText: translate.common.Continue,
                        confirmButtonColor: "blue"
                      });
                      if (!confirm) {
                        return;
                      }
                    }
                    await getBifrost().team__server__setTeamAccount.fetchServer({
                      accountId: p.playerAccount.id,
                      teamAccount,
                      teamId: p.team.id,
                      forceRecalculateDerived: true
                    });

                    openSuccessToast(
                      translate({
                        defaultMessage: `Updated ${p.player.derived.accountInfo.firstName} ${p.player.derived.accountInfo.lastName}`
                      })
                    );
                  }
                  p.onRequestDismiss();
                } catch (e) {
                  console.log(e);
                  openErrorToast(
                    translate({
                      defaultMessage: "There was a problem with the update. Please try again or contact support@olliesports.com"
                    })
                  );
                }
              }
            }}
          >
            <View>
              <View style={{ flexDirection: "row", marginBottom: 15 }}>
                <View style={{ width: "30%", paddingRight: 16 }}>
                  <ShadowView style={{ borderRadius: 200 }}>
                    {playerDetails.profileImageUri ? (
                      <>
                        <img
                          src={playerDetails.profileImageUri}
                          alt="description"
                          className="max-w-full h-auto rounded-full object-cover"
                        />
                        <button
                          className="absolute w-8 h-8 shadow-harsh m-1 bg-white top-1 right-1 p-1.5 rounded-full"
                          onClick={async () => {
                            setPlayerDetails({ profileImageUri: undefined, profileImageUriSmall: undefined });
                          }}
                        >
                          <TrashIcon color={COLORS.red} />
                        </button>
                      </>
                    ) : (
                      <button onClick={() => document.getElementById(INPUT_ID)!.click()}>
                        <SingleImageDropZone
                          onDrop={handleImage}
                          dragClassName="bg-green-300"
                          className="flex w-full aspect-[1/1] rounded-full bg-gray-200 items-center justify-center hover:opacity-70"
                        >
                          <View style={{ width: 50, height: 50 }}>
                            <CameraIcon color={"white"} />
                          </View>
                        </SingleImageDropZone>
                      </button>
                    )}
                  </ShadowView>
                  <input
                    id={INPUT_ID}
                    accept={"image/*"}
                    multiple={false}
                    onChange={async event => {
                      var file = event.target.files?.[0];
                      if (file) {
                        handleImage(file);
                      }
                    }}
                    type="file"
                    style={{ display: "none" }}
                  />
                  <canvas ref={canvasRef} style={{ display: "none" }} />
                </View>
                <View style={{ flex: 1 }}>
                  <PrettyCoolTextInputWithLabel
                    style={{ flex: 1, marginRight: 4 }}
                    label={translate.common.FirstName}
                    onChange={newVal => {
                      setPlayerDetails({ firstName: newVal });
                    }}
                    value={playerDetails.firstName}
                  />
                  <PrettyCoolTextInputWithLabel
                    style={{ flex: 1, marginTop: 15 }}
                    label={translate.common.LastName}
                    onChange={newVal => {
                      setPlayerDetails({ lastName: newVal });
                    }}
                    value={playerDetails.lastName}
                  />
                  <PrettyCoolTextInputWithLabel
                    style={{ flex: 1, marginTop: 15, marginBottom: 15 }}
                    inputProps={{ type: "number" }}
                    label={translate.common.JerseyNumber}
                    onChange={newVal => {
                      setPlayerDetails({ jerseyNumber: newVal?.replaceAll("-", "") ?? "" });
                    }}
                    value={playerDetails.jerseyNumber}
                  />
                </View>
              </View>
              {p.initialTeamAccount && teamAccount ? (
                <TeamAccountEdit
                  onUpdateTeamAccount={newTeamAccount => {
                    setTeamAccount(newTeamAccount);
                  }}
                  teamAccount={teamAccount as Team__Account}
                />
              ) : null}

              <button
                className="self-end inline-flex items-center gap-2 text-red-500 py-4  rounded"
                disabled={isDeleting}
                onClick={async () => {
                  const confirm = await getConfirm({
                    title: translate(
                      { defaultMessage: `Remove {name} from Roster?` },
                      { name: p.player.derived.accountInfo.firstName }
                    ),
                    subtitle: translate({
                      defaultMessage:
                        "Removing the player from the roster will also remove any attached parent/guardian accounts from the team unless those parents/guardians are staff members or have another child on the team."
                    }),
                    cancelText: translate.common.Cancel,
                    confirmText: translate.common.Remove,
                    confirmButtonColor: "red"
                  });
                  if (confirm) {
                    const loader = openLoadingIndicator();
                    setIsDeleting(true);
                    try {
                      await getBifrost().player__server__deleteFromTeam.fetchServer({ data: { playerId: p.player.player.id } });
                      openSuccessToast(
                        translate({
                          defaultMessage: `Removed ${p.player.derived.accountInfo.firstName} ${p.player.derived.accountInfo.lastName}`
                        })
                      );

                      const newPath = window.location.pathname.split("/").slice(0, -1).join("/");
                      console.log(newPath);
                      try {
                        history.replace(newPath);
                      } catch (e) {
                        console.error(e);
                      }

                      p.onRequestDismiss();
                    } catch (e) {
                      openErrorToast(
                        translate({
                          defaultMessage:
                            "There was a problem removing the player. Please try again or contact support@olliesports.com"
                        })
                      );
                    }
                    loader.close();
                    setIsDeleting(false);
                  }
                }}
              >
                <span>{translate({ defaultMessage: "Remove Player" })}</span>
                <div style={{ width: 20, height: 20 }}>
                  <TrashIcon />
                </div>
              </button>
            </View>
          </FullScreenModal>
        );
      }}
    />
  );
}
