import { TeamId, Team } from "@ollie-sports/models";
import * as express from "express";
import { validateToken } from "../../internal-utils/server-auth";
import { getUniversalHelpers } from "../../helpers";

export async function team__server__getTeams(p: { teamIds: TeamId[] }): Promise<Team[]> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  const teamsTemp = await h.Team.getDocs(p.teamIds);
  const teams: Team[] = [];
  teamsTemp.forEach(t => {
    if (t) {
      teams.push(t);
    }
  });
  return teams;
  // SERVER_ONLY_TOGGLE
}
team__server__getTeams.auth = async (r: express.Request) => {
  await validateToken(r);
};

// i18n certified - complete
